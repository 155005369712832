import { useState } from "react";
import axios from "axios";
const Contact = () => {
    return (
        <div className="pt-2 pb-2" id='contact' style={{ backgroundColor: "#a1887f" }}>
            <div>

                <div className="row">
                    <div className="col ml-2">
                        <h5>Nuestras oficinas</h5>
                        <ul className="list-group">
                            <li className="list-group-item" style={{ backgroundColor: "#efebe9" }}>- Clorinda, Fsa.: Juan Manuel Estrada 849, telef. Fijo 03718-431750</li>
                            <li className="list-group-item" style={{ backgroundColor: "#efebe9" }}>- Ciudad de Formosa, Av. Gutnisky 1952, cel. 3704-314785</li>
                            
                            <li className="list-group-item" style={{ backgroundColor: "#efebe9" }}>- V. Soldati, Castañon 3463, CABA (Logistica Mis Nenas)</li>
                        </ul>
                    </div>
                    <div className="col mr-2">
                        <h5>¡Contactanos!</h5>
                        <Form />
                    </div>
                </div>
            </div>

        </div>
    );
}

const Form = () => {
    const [field, setField] = useState({
        office: "",
        mail: "",
        name: "",
        email: "",
        subject: "",
        message: "",
        alert: ""
    });
    const [alert, setAlert] = useState(false);
    const [message, setMessage] = useState("")
    let sendMail = async (e) => {
        e.preventDefault();
        console.log(`SendMail => INIT`);
        try {
            console.log(field);
            setAlert(true)
            
            axios.post("/sendEmail.php", field).then((res) => {
                console.log(res.data);
                if (res.data.status) {
                    setMessage(res.data.message);
                    setAlert(true)
                    e.target.reset();
                }else{
                    setMessage(res.data.message);
                    setAlert(true)
                }

            })
            
        } catch (error) {
            console.log(error);
            if (error) {
                setMessage("Hubo un problema, por favor intente mas tarde");
                setAlert(true)
            }
        }

    }
    const closeAlert = () => {
        setAlert(false);
    }
    return (
        <div>
            <form onSubmit={sendMail}>
                <div className="form-group">
                    <select onChange={(e) => setField({ ...field, office: e.target.value })} required className="form-control" aria-label="Default select example">
                        <option>Seleccionar oficina</option>
                        <option value="consultasfsa@transportelosandes.com.ar">Oficina Formosa</option>
                        <option value="consultasclda@transportelosandes.com.ar">Oficina de Clorinda</option>
                    </select>
                </div>
                <div className="form-group">
                    <input onChange={(e) => setField({ ...field, mail: e.target.value })} required type="email" className="form-control" placeholder="Email" />
                </div>
                <div className="form-group">
                    <input onChange={(e) => setField({ ...field, name: e.target.value })} required type="text" className="form-control" placeholder="Nombre" />
                </div>
                <div className="form-group">
                    <input onChange={(e) => setField({ ...field, subject: e.target.value })} required type="text" className="form-control" placeholder="Asunto" />
                </div>
                <div className="form-group">
                    <textarea onChange={(e) => setField({ ...field, message: e.target.value })} required className="form-control" placeholder="Mensaje"></textarea>
                </div>
                <div>

                    <button type="submit" className="btn btn-primary">Enviar</button>
                </div>
                {
                    alert && (
                        <div className="alert alert-light">
                            {message}
                            <button onClick={closeAlert} type="button" class="btn">Cerrar</button>
                        </div>
                    )
                }
            </form>
        </div>
    );
}

export default Contact;