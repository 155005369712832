const Footer = () => {
    let date = new Date().getFullYear();
    return (
        <div>
            <footer className="page-footer" style={{backgroundColor: "#5d4037", color: "#bcaaa4"}}>
                <div className="pt-2 pb-2">
                    <div className="container">
                        © {date} Copyright Todos los derechos reservados
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default Footer