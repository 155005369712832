import { useState } from "react";

const Navs = () => {
    let [show, setShow] = useState(false);
    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark" id="home">
                <a className="navbar-brand" href="#">
                    <img src="assets/logo_blanco.png" width="40%" height="40%" />
                </a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                    <div className="navbar-nav">
                        <a className="nav-link" href="#home">Inicio</a>
                        <a className="nav-link" href="#services">Servicios</a>
                        <a className="nav-link" href="#aboutus">Quienes somos</a>
                        <a className="nav-link" href="#contact">Contacto</a>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default Navs;