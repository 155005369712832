import { useEffect } from "react";

const Carousel = () => {
    let imgs = [
        {url: "/assets/photos/photo1.jpeg"},
        {url: "/assets/photos/photo2.jpeg"},
        {url: "/assets/photos/photo3.jpeg"},
        {url: "/assets/photos/photo4.jpeg"},
        {url: "/assets/photos/photo5.jpeg"},
    ];

    return (
        <div>
            <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                <div className="carousel-inner">
                    {
                        imgs.map((img, index) => {
                            return (
                                <div key={index} className={`carousel-item ${index === 0 ? "active" : ""}`}>
                                    <img src={img.url} className="d-block w-100" alt="..." />
                                </div>
                            );
                        })
                    }
                </div>
                <button className="carousel-control-prev" type="button" data-target="#carouselExampleControls" data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-target="#carouselExampleControls" data-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                </button>
            </div>
        </div>
    );
}

export default Carousel;