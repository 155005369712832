import { useState } from 'react';
const AboutUs = () => {
    const [show, setShow] = useState(false);
    return (
        <div className="pt-2 pb-2" id='aboutus' style={{backgroundColor:"#ffd54f"}}>
            <div className='row'>
                <div className='col ml-2'>
                    <img src="assets/oficina.jpeg" className='img-fluid p-2 shadow' style={{backgroundColor:"#ffecb3"}} />
                </div>
                <div className='col'>
                    <h5>Quienes somos</h5>
                    <p>
                        Somos una empresa que se especializa en servicios de transporte (mudanzas, fletes, encomiendas),
                        con mas de 10 años de experiencia en el rubro. Contamos con la infraestructura necesaria para realizar
                        un servicio de excelente calidad.
                        Cubrimos CABA, GBA, Rosario, Santa Fe, Formosa capital, Clorinda y zonas cercanas.
                    </p>
                </div>

            </div>
        </div>
    );
}

export default AboutUs;