import { useState } from 'react';
const Services = () => {
    let serviceData = [
        { title: "Transporte", description: "Expreso de paquetería desde Capital Federal y Rosario a Formosa e interior provincial", color: "blue-grey darken-1" },
        { title: "Transporte", description: "Transporte de cargas generales, mudanzas", color: "grey darken-1" },
        { title: "Transporte", description: "Fletes corta y larga distancia", color: "green darken-1" },
        { title: "Transporte", description: "Mudanzas de cajas fuertes", color: "red darken-1" },
        { title: "Transporte", description: "Servicio de carga, descarga y guarda de mercadería palletizada en Clorinda", color: "orange darken-1" },
        { title: "Transporte", description: "Servicio de distribución en Clorinda y Formosa", color: "orange darken-1" },
        { title: "Transporte", description: "Transporte internacional al Paraguay, consolidamos la carga en Clorinda", color: "orange darken-1" },
    ];
    return (
        <div className="pt-2 pb-2" id='services' style={{ backgroundColor: "#ffcc80" }}>

            <div className='row'>
                <div className='col ml-2'>
                    <h5 className='mx-auto'>Servicios</h5>
                    <ul className='list-group '>
                        {serviceData.map((service, index) => (

                            <li key={index} className="list-group-item" style={{ backgroundColor: "#ffe0b2"  }}>
                                - {service.description}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className='col mr-2'>
                    <img src="assets/tla.jpeg" className='img-fluid p-2 shadow' style={{backgroundColor: "#fff3e0"}} />
                </div>
            </div>
        </div>
    );
}

export default Services;