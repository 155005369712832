import Carousel from "./components/Carousel";
import Navs from "./components/Nav";
//import "bootstrap/dist/css/bootstrap.min.css";
import Services from "./components/Services";
import AboutUs from "./components/AboutUs";
import Footer from "./components/Footer";
import Contact from "./components/Contact";
import {useEffect} from "react";

function App() {
  useEffect(() => {
    document.querySelector("body").style.background = "#212121";
  });
  return (
    <>
      <div className="container deep-orange lighten-4">
        <Navs />
        <Carousel />
        <Services />
        <AboutUs />
        <Contact />
        <Footer />
      </div>
    </>
  );
}

export default App;
